@import '../../styles/partials/variables';

:root {
  --cursor-x: 0.5;
  --cursor-y: 0.5;
}

.cursor-reactive-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: -1;
  background: radial-gradient(
    circle at calc(var(--cursor-x) * 100%) calc(var(--cursor-y) * 100%),
    rgba($textColor, 0.05),
    rgba($backgroundColor, 0) 25%
  );
  transition: background 0.3s ease;
}