@import 'variables';
@import 'fonts';

// Media queries mixin (using variables for breakpoints)
@mixin respondTo($breakpoint) {
    @if $breakpoint == mobile {
        @media (min-width: $breakpointMobile) {
            @content;
        }
    }
    @else if $breakpoint == tablet {
        @media (min-width: $breakpointTablet) {
            @content;
        }
    }
    @else if $breakpoint == desktop {
        @media (min-width: $breakpointDesktop) {
            @content;
        }
    }
}

// Centering mixin using Flexbox
@mixin flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

// Mixin for button styling (using updated variables and camelCase)
@mixin button($bgColor: $primaryColor, $textColor: $textColor) {
    background-color: $bgColor;
    color: $textColor;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    transition: background-color 0.3s;

    &:hover {
        background-color: lighten($bgColor, 10%);
    }
}

// Mixin for box-shadow
@mixin boxShadow($x: 0px, $y: 4px, $blur: 6px, $color: rgba(0, 0, 0, 0.1)) {
    box-shadow: $x $y $blur $color;
}
