// Import global variables and mixins
@import './partials/_variables';
@import './partials/_mixins';

// Global reset (reset margins, padding, box-sizing)
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

// Body styling
body {
	font-family: $fontFamily;
	background-color: $backgroundColor;
	color: $textColor;
	line-height: 1.6;
}

// Default link styles
a {
	color: rgba($textColor, 0.6);
	text-decoration: none;
	cursor: pointer;

	&:hover {
		color: rgba($textColor, 1);
	}
}

li {
	list-style: none;
}

// Global typography styles
h1 {
	font-size: 2.25rem;
	font-weight: 700;
	line-height: 1.2;
	margin-bottom: 1rem;
}

h2,
h3,
h4,
h5,
h6 {
	font-weight: 500;
}

h2 {
	font-size: 1.125rem;
}

h3 {
	font-size: 1rem;
}

p {
	font-size: 1rem;
	color: $altTextColor;
}

button {
	cursor: pointer;
	font-family: inherit;
	font-size: inherit;
	color: inherit;
	background: none;
	border: none;
	padding: 0;
	margin: 0;
	text-align: inherit;
}

// Responsive typography example
@include respondTo(tablet) {
	body {
		font-size: 1.1rem;
	}

	h1 {
		font-size: 3rem;
	}
}

@include respondTo(desktop) {
	body {
		font-size: 1.2rem;
	}

	h1 {
		font-size: 4rem;
	}
}

.container {
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 $spacingMd;
}

header {
	background-color: $altBackgroundColor;
	padding: $spacingMd 0;
}

.cursor {
	animation: blink 1s step-end infinite;
}

.tabs {
	display: flex;
	margin-top: $spacingMd;

	a {
		background-color: $backgroundColor;
		color: $altTextColor;
		padding: 0.5rem 1rem;
		text-decoration: none;
		border-top-left-radius: 0.25rem;
		border-top-right-radius: 0.25rem;
		margin-right: 0.25rem;
		transition: background-color 0.3s ease;

		&:hover,
		&.active {
			background-color: $altBackgroundColor;
			color: $textColor;
		}
	}
}

.line-numbers {
	color: $altTextColor;
	margin-right: 1rem;
	user-select: none;
}

.code-comment {
	color: $accentGreen;
}

.keyword {
	color: $accentBlue;
}

.variable {
	color: $accentLightBlue;
}

.string {
	color: $accentOrange;
}

.function {
	color: $accentYellow;
}

section {
	position: relative;
}

.file-tree {
	background-color: $altBackgroundColor;
	padding: $spacingMd;
	border-radius: 4px;

	ul {
		list-style-type: none;
		padding-left: $spacingMd;
	}

	li {
		margin-bottom: $spacingSm;
	}

	.folder::before {
		content: "📁";
		margin-right: 5px;
	}

	.file::before {
		content: "📄";
		margin-right: 5px;
	}
}

.status-bar {
	background-color: $altBackgroundColor;
	color: $altTextColor;
	padding: $spacingSm $spacingMd;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: space-between;
}

@keyframes blink {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.code-comment {
	color: $accentGreen;
}

.keyword {
	color: $accentBlue;
}

.variable {
	color: $accentLightBlue;
}

.brace-1 {
	color: $braceYellow;
}

.brace-2 {
	color: $bracePurple
}

.brace-3 {
	color: $braceBlue;
}