@import '../../styles/main.scss';
@import '../../styles/partials/variables';
@import '../../styles/partials/mixins';

.sidebar {
    display: flex;
    flex-direction: column;
    color: $textColor;
    padding: 3rem 1.5rem 0 1.5rem;
    width: 100%;
    text-align: left;

    &__name {
        margin: 0;
    }

    &__title-container {
        min-height: 1.5em;
        display: flex;
        align-items: center;
    }

    &__title {
        display: inline-block;
        position: relative;
        color: $accentYellow;
        
        .typed-cursor {
            opacity: 1;
            animation: blink 0.7s infinite;
            color: $accentYellow;
            font-weight: 100;
            margin-left: 2px;
        }
    }

    &__nav {
        display: none;

        @include respondTo(desktop) {
            display: block;
        }

        margin-top: 2rem;

        ul {
            list-style-type: none;
            padding: 0;

            li {
                display: flex;
                align-items: center;
                margin-bottom: 0.5rem;
                transition: all 0.3s ease;
                padding-left: 0;

                &.active {
                    padding-left: 2rem;

                    .chevron-icon {
                        opacity: 1;
                        color: $primaryColor;
                    }

                    button {
                        color: $primaryColor;
                    }
                }

                .chevron-icon {
                    font-size: 0.8rem;
                    margin-right: 0.5rem;
                    opacity: 0;
                    transition: all 0.3s ease;
                    color: $textColor;
                }

                button {
                    background: none;
                    border: none;
                    color: $textColor;
                    cursor: pointer;
                    font-size: 1rem;
                    padding: 0.5rem 0;
                    transition: all 0.3s ease;
                    text-align: left;
                    width: 100%;
                }

                &:hover:not(.active) {
                    padding-left: 2rem;

                    .chevron-icon {
                        opacity: 1;
                        color: $primaryColor;
                    }

                    button {
                        color: $primaryColor;
                    }
                }
            }
        }
    }

    &__links {
        display: flex;
        align-items: center;
        gap: 2rem;
        margin-top: 2rem;
        font-size: 1.75rem;
    }

    @include respondTo(tablet) {
        padding: 0;
    }

    @include respondTo(desktop) {
        height: 100vh; // Full viewport height
        justify-content: space-between;
        padding: 6rem 3rem;
        position: sticky;
        top: 0;
        bottom: 0;
        left: 0;
    }
}

@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}
