@import '../../styles/main.scss';

.experience {
	display: flex;
	flex-direction: column;
	padding: 2rem 0;

	&__title {
		color: $textColor;
		font-weight: 700;
		font-size: 0.875rem;
		margin-bottom: 2rem;
	}

	&__list {
		position: relative;
		padding-left: 1rem;
		padding-top: 0.5rem;

		&:before {
			content: '';
			position: absolute;
			left: 2px;
			top: 0;
			bottom: 0;
			width: 2px;
			background-color: $altTextColor;
		}
	}

	@include respondTo(desktop) {
		padding: 0;
		margin: 6rem 0;
	}
}
