@font-face {
    font-family: "Helvetica";
    src: local("Helvetica Light");
    src: url(../../assets/fonts/helvetica-light-587ebe5a59211.ttf) format("opentype");
    font-weight: 400;
};

@font-face {
    font-family: "Helvetica";
    src: local("Helvetica Regular");
    src: url(../../assets/fonts/Helvetica.ttf) format("opentype");
    font-weight: 500;
};

@font-face {
    font-family: "Helvetica";
    src: local("Helvetica Bold");
    src: url(../../assets/fonts/Helvetica-Bold.ttf) format("opentype");
    font-weight: 700;
};

@font-face {
    font-family: "Graphik";
    src: local("Graphik Light");
    src: url(../../assets/fonts/Graphik/Graphik-Light-Trial.otf) format("opentype");
    font-weight: 400;
};

@font-face {
    font-family: "Graphik";
    src: local("Graphik Regular");
    src: url(../../assets/fonts/Graphik/Graphik-Regular-Trial.otf) format("opentype");
    font-weight: 500;
};

@font-face {
    font-family: "Graphik";
    src: local("Graphik Medium");
    src: url(../../assets/fonts/Graphik/Graphik-Medium-Trial.otf) format("opentype");
    font-weight: 600;
};

@font-face {
    font-family: "Graphik";
    src: local("Graphik SemiBold");
    src: url(../../assets/fonts/Graphik/Graphik-Semibold-Trial.otf) format("opentype");
    font-weight: 700;
};

@font-face {
    font-family: "Graphik";
    src: local("Graphik Bold");
    src: url(../../assets/fonts/Graphik/Graphik-Bold-Trial.otf) format("opentype");
    font-weight: 800;
};

@font-face {
    font-family: "Graphik";
    src: local("Graphik Black");
    src: url(../../assets/fonts/Graphik/Graphik-Black-Trial.otf) format("opentype");
    font-weight: 900;
};