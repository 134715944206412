@import '../../styles/partials/variables';
@import '../../styles/partials/mixins';

.profile {
	display: flex;
	flex-direction: column; // Mobile first: Stack content vertically
	padding: 0;

	&__content {
		position: relative;
		padding-left: 1rem;
		padding-top: 0.5rem;
		margin-bottom: 1rem;

		&:before {
			content: '';
			position: absolute;
			left: 2px;
			top: 0;
			bottom: 0;
			width: 2px;
			background-color: $altTextColor;
		}

		p {
			color: $textColor;
			font-size: 0.875rem;
			line-height: 1.6;
			margin-bottom: 1rem;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__details {
		text-align: left;
		padding-right: 0.5rem;
	}

	.code-content {
		padding: 1rem 0;
		border-radius: 0.5rem;
	}

	// Responsive adjustments
	@include respondTo(desktop) {
		padding: 0;
		margin-bottom: 6rem;

		.code-content {
			padding: 0;
		}

		.profile__details {
			padding-left: 1rem;
			text-align: left;
		}
	}
}
