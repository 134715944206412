@import './styles/partials/variables';
@import './styles/partials/mixins';

html {
    scroll-behavior: smooth;
}

.App {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: $backgroundColor;
    color: $textColor;

    @include respondTo(desktop) {
        flex-direction: row;
    }

    &__container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;

        @include respondTo(desktop) {
            flex-direction: row;
        }
    }

    &__sidebar {
        width: 100%;

        @include respondTo(tablet) {
            padding: 3rem;
            padding-bottom: 0;
        }

        @include respondTo(desktop) {
            width: 45%; // Adjust as needed
            position: sticky;
            left: 0;
            top: 0;
            bottom: 0;
            padding: 0;
        }
    }

    &__content {
        width: 100%;
        padding: 1.5rem;

        @include respondTo(tablet) {
            padding: 3rem;
        }

        @include respondTo(desktop) {
            width: 55%; // Adjust to complement sidebar width
            padding: 6rem 3rem;
        }
    }
}
