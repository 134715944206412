// Color Variables (Dark Modern theme)
$primaryColor: #CE9178;  // Orange
$backgroundColor: #1E1E1E;  // Dark background
$altBackgroundColor: #252526;  // Slightly lighter background
$textColor: #D4D4D4;  // Light grey for main text
$altTextColor: #858585;  // Darker grey for comments

// Accent colors
$accentGreen: #6A9955;  // Green (comments in Dark Modern)
$accentYellow: #DCDCAA;  // Yellow (functions in Dark Modern)
$accentBlue: #569CD6;  // Blue (keywords in Dark Modern)
$accentLightBlue: #9CDCFE;  // Light Blue (variables in Dark Modern)
$accentOrange: #CE9178;  // Orange (strings in Dark Modern)
$accentPurple: #C586C0;  // Purple (new accent color)

// Brace colors
$braceBlue: #169FFF;  // Brace Blue (variables in Dark Modern)
$braceYellow: #FFD602;  // Brace Yellow (functions in Dark Modern)
$bracePurple: #DA70D6;  // Brace Purple (keywords in Dark Modern)

// Font
$fontFamily: 'Fira Code', 'Consolas', monospace;

// Font Sizes
$fontSizeBase: 1rem;
$fontSizeLg: 1.125rem;
$fontSizeXl: 1.5rem;
$fontSizeXxl: 3rem;

// Spacing
$spacingSm: 0.625rem;
$spacingMd: 1.25rem;
$spacingLg: 2.5rem;

// Margins
$marginSm: 3rem;
$marginMd: 5rem;
$marginLg: 6rem;

// Breakpoints
$breakpointMobile: 320px;
$breakpointTablet: 768px;
$breakpointDesktop: 1280px;
