@import '../../styles/partials/variables';
@import '../../styles/partials/mixins';

.card {
    position: relative;
    padding: 1rem 0 1rem 1rem;

    &:not(:last-child) {
        margin-bottom: 1rem;
    }

    &__brace {
        display: block;
        font-size: 1rem;
        line-height: 1;
        position: absolute;
        left: 0;

        &--open {
            top: -0.25rem;
            left: 1rem;
        }

        &--close {
            bottom: -0.25rem;
            left: 1rem;
        }

        &--comma {
            bottom: -0.25rem;
            left: 1.75rem;
            color: $altTextColor;
        }
    }

    &__content {
        border-left: 2px solid $altTextColor;
        padding-left: 1rem;
        margin: 0.25rem 0;
    }

    &__date {
        color: $altTextColor;
        font-size: 0.75rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
    }

    &__details {
        display: flex;
        flex-direction: column;
    }

    &__header {
        margin-bottom: 0.5rem;

        h3 {
            color: $primaryColor;
            font-weight: 500;
        }

        .card__subtitle {
            margin-top: 0.25rem;

            h4 {
                color: $textColor;
                font-size: 0.875rem;
                margin: 0.25rem 0;
            }
        }
    }

    &__body {
        p {
            font-size: 0.875rem;
            line-height: 1.6;
            color: $textColor;
        }
    }

    &__tags {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        margin-top: 1rem;

        .card__tag {
            background-color: rgba($accentYellow, 0.2);
            color: $accentYellow;
            padding: 0.25rem 0.75rem;
            border-radius: 2rem;
            font-size: 0.75rem;
            font-weight: 500;
        }
    }

    &__link {
        color: inherit;
        text-decoration: none;
        
        &:hover {
            color: $accentYellow;
        }
    }

    @include respondTo(desktop) {
        padding: 1rem;

        &:hover {
            // background-color: lighten($altBackgroundColor, 5%);
            .card__content {
                border-left-color: white;
            }
        }

        &.dimmed {
            opacity: 0.5;
            transition: all 0.3s ease-in-out;
        }
    }
}
